import React, { isValidElement } from "react";
import { Button } from "antd";
import Typography from "antd/es/typography/Typography";
import { useEffect, useState } from "react";
import { getUserData } from "../Layouts/Navbar/Requests";
import { Navigate, useLocation } from "react-router-dom";

export function UnauthorizedPage() {
  const [isLogged, setIsLogged] = useState(false);

  const { Title } = Typography;

  let { pathname: location } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserData();
        setIsLogged(true);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setIsLogged(false);
        } else {
          console.log("Error:", error.message);
          setIsLogged(false);
        }
      }
    };
    fetchData();
  }, [location]);

  return (
    <div>
      {isLogged == true ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
            }}
          >
            <img
              src="https://quote.flowinsurance.com/static/media/Koala_Face.0ae4f98c.svg"
              alt="Flowflood"
              width="20%"
            />
          </div>

          <div
            style={{
              justifyContent: "center",
              display: "flex",
              padding: "50px",
            }}
          >
            <Typography>
              <Title>401 Unauthorized</Title>
            </Typography>
          </div>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button size="large" type="primary" href="/home">
              Go Back
            </Button>
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
}
