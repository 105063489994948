import React from "react";
import { Menu } from "antd";

const LeftMenu = ({ mode, apiData }) => {
  const items = apiData?.navBarItems.map(
    (element) =>
      element.parent == null && {
        label: element.permission.displayName,
        key: element.permission.id,
        children: element.children
        ?.filter((element) => element.url !== null) // Exclude elements with null URL
        .map((element) => ({
          key: element.id,
          label: (
            <a
              style={{ textDecoration: "none" }}
              href={element.url}
              rel="noopener noreferrer"
            >
              {element.displayName}
            </a>
          ),
          disabled: false,
        })),
      }
  );
  return <Menu theme="light" mode={mode} items={items} />;
};

export default LeftMenu;
