import React from "react";
import { Menu, Avatar } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { signOut } from "./Requests";
import { useNavigate } from "react-router-dom";

const RightMenu = ({ mode, apiData }) => {
  
  const signOutAction = async () => {
    signOut();
    window.location = "/login";
  };

  return (
    <Menu mode={mode}>
      <span
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "10px",
        }}
        color="black"
      >
        <p style={{whiteSpace: 'nowrap'}}>Hi, {apiData?.userData?.name} {apiData?.userData?.lastname}</p>

      </span>
      <Menu.SubMenu
        key={Math.random()}
        title={
          <>
            <Avatar
              size={40}
              shape="circle"
              icon={<UserOutlined style={{fontSize: 20}} />}
            />
          </>
        }
      >
        {/* <Menu.Item key="project">
          <CodeOutlined /> Projects
        </Menu.Item>
        <Menu.Item key="about-us">
          <UserOutlined /> Profile
        </Menu.Item> */}
        <Menu.Item
          onClick={signOutAction}
          key="log-out"
          icon={
            <LogoutOutlined
              style={{
                color: "#194FA2",
              }}
            />
          }
        >
          Logout
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default RightMenu;
