import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const getUserData = async () => {
  const response = await axios.get(`${baseURL}/auth/getUserData`, {
    headers: {
      Authorization: `Basic ${btoa(
        `${process.env.REACT_APP_HEADER_USER}:${process.env.REACT_APP_HEADER_PASSWORD}`
      )}`,
    },
    withCredentials: true,
  });
  return response.data;
};

export const signOut = async () => {
  return axios(
    {
      method: "POST",
      url: `${baseURL}/auth/api/auth/signOut`,
      withCredentials: true,
    }
  );
};
