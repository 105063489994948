import React, { useState, useEffect } from "react";
import { Layout, Button, Drawer } from "antd";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import { useLocation } from "react-router-dom";
import { getUserData } from "./Requests";
import { LoadingOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

export const Navbar = () => {
  const [apiData, setApiData] = useState(null);

  let { pathname: location } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();
        setApiData(data);
      } catch (error) {
        console.log("Error:", error.message);
      }
    };
    fetchData();
  }, [location]);

  return (
    <Layout>
      <Header
        className="nav-header"
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <a className="logo" href="/home">
          <img
            src="https://flowflood.westus3.cloudapp.azure.com/api/resources/common/img/logo-dark.svg"
            alt=""
            width={130}
            style={{marginRight: 20}}
          />
        </a>
        {apiData ? (
          <div
            style={{
              width: "100%",
              justifyContent: "flex-start",
              alignItems:'flex-start'
            }}
          >
            <LeftMenu mode={"horizontal"} apiData={apiData} />
          </div>
        ) : (
          <LoadingOutlined
            style={{
              fontSize: "40px",
              color: "orange",
            }}
          />
        )}
        <RightMenu mode={"horizontal"} apiData={apiData} />
      </Header>
    </Layout>
  );
};

export default Navbar;
