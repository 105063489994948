import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "../Layouts/Navbar/Requests";

export const ActionsProtection = () => {
  const [isValidated, setIsValidated] = useState(false);
  const [userData, setUserData] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [isLogged, setIsLogged] = useState(false);

  let { pathname: location } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUserData();
        setUserData(user);
        setIsValidated(
          user?.navBarItems?.some(
            (item) =>
              item.permission.id === 'f4a67c2d-e564-4f39-b8a0-482f31345589'
          )
        );
        setIsLogged(true);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setIsLogged(false);
        } else {
          console.log("Error:", error.message);
          setIsLogged(false);
        }
      } finally {
        setIsFetching(false);
      }
    };
    fetchData();
  }, [location]);

  const redirectToLogin = () => {
    localStorage.setItem("forwardUrl", window.location.pathname);
    return <Navigate to={"/login"} />;
  };

  if (isFetching) {
    return null;
  }

  if (!isLogged) {
    return redirectToLogin();
  }

  if (!userData || !userData.navBarItems || !isValidated) {
    return <Navigate to={"/unauthorized"} />;
  }

  return <Outlet />;
};
