import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "../Layouts/Navbar/Requests";

export const PrivateRoutes = () => {
  const [isValidated, setIsValidated] = useState(true);

  //VERIFY USER HAS LOGIN TOKEN PROVIDED

  let { pathname: location } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserData();
        setIsValidated(true);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setIsValidated(false);
        } else {
          console.log("Error:", error.message);
          setIsValidated(false);
        }
      }
    };
    fetchData();
  }, [location]);

  return isValidated ? <Outlet /> : <Navigate to="/login" />;
};
