import axios from "axios";
// import React, { useEffect, useState, useRef } from "react";

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

// Login
export const signIn = async (postParameters) => {
  return axios(
    {
      method: "POST",
      url: `${baseURL}/auth/api/auth/signin`,
      validateStatus: () => true,
      data: postParameters,
      withCredentials: true,
    },
    {
      headers: {
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_HEADER_USER}:${process.env.REACT_APP_HEADER_PASSWORD}`
        )}`,
      },
    }
  );
};

export const updateAgentsToManage = async (reviewedAgents) => {
  return axios.post(
    `${baseURL}/updateAgentsToManage`,
    { reviewedAgents },
    {
      headers: {
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_HEADER_USER}:${process.env.REACT_APP_HEADER_PASSWORD}`
        )}`,
      },
    }
  );
};
