import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Homepage
import { LandingPage } from "./Components/Home/LandingPage";

// Protected endpoints
import { ActivityReport, ActivityReportWorka, BordereauReport, BordereauReportWip } from "./Components/Reports";
import { SLANumberWip,SLANumber } from "./Components/Actions";
import { LoginSite } from "./Components/Auth";
import { ReportsProtection } from './Components/ReportsProtection';
import { ActionsProtection } from './Components/ActionsProtection';
import { PrivateRoutes } from "./Components/PrivateRoutes"

// Web components
import { PageNotFound } from './Components/PageNotFound';
import { UnauthorizedPage } from './Components/UnauthorizedPage';


const antIcon = <LoadingOutlined style={{ fontSize: 28, marginRight: 10 }} spin />;
Spin.setDefaultIndicator(<Spin indicator={antIcon} />);

function App() {

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#194FA2',
            "borderRadius": 10,
            "fontSize": 14.5,
            // "fontFamily": 'Poppins',
            'colorPrimaryHover': 'rgb(50,50,50)'
          }
        }}
      >
        <Routes>

          <Route path='/login' element={<LoginSite />} />

          <Route path='/unauthorized' element={<UnauthorizedPage />} />

          <Route path='*' element={<PageNotFound />} />

          <Route element={<PrivateRoutes />}>

            <Route path='/' element={<LandingPage />} />

            <Route path='/home' element={<LandingPage />} />

            <Route element={<ReportsProtection />}>

              <Route path='/reports/ActivityReport' element={<ActivityReport />} />

              <Route path='/reports/BordereauReport' element={<BordereauReport />} />
              
              <Route path='/reports/BordereauReportwip' element={<BordereauReportWip />} />
              
              <Route path='/reports/ActivityReportwip' element={<ActivityReportWorka />} />

              <Route path='/reports/SLANumberwip' element={<SLANumberWip />} />

            </Route>

            <Route element={<ActionsProtection />}>

              <Route path='/actions/SLANumberwip' element={<SLANumberWip />} />
              <Route path='/actions/SLANumber' element={<SLANumber />} />

            </Route>

          </Route>

        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
