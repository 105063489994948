import axios from "axios";
import { createSlice } from "@reduxjs/toolkit"

// GLOBAL STATE ACCESSIBLE OVER ALL THE COMPONENTS
const initialState = {
  items: [],
  loading: false,
  error: null
}

// PORTION OF THE GLOBAL STATE, IN THIS CASE NAMED 'REDUCER'
// CONTAINS ACTIONS WITH RULES AND REDUCERS THAT AFFECTS THE STATE
const slice = createSlice({
  name: 'reducer',
  initialState,
  reducers: {

  }
})

// Activity Report
export const getActivityReportData = async (searchFilters) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/activityReport/data`,
    {
      withCredentials: true,
      params: searchFilters
    }
  );
};

export const getActivityReportRegistryCount = async (searchFilters) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/activityReport/count`,
    {
      withCredentials: true,
      params: searchFilters
    }
  );
};

export const getActivityReportSelects = async () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/activityReport/selects`,
    {
      withCredentials: true
    }
  );
};

// SLA Number
export const getSLANumberData = async (searchFilters) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/SLANumber/data`,
    {
      withCredentials: true,
      params: searchFilters
    }
  );
};

export const getSLANumberRegistryCount = async (searchFilters) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/SLANumber/count`,
    {
      withCredentials: true,
      params: searchFilters
    }
  );
};

export const postSLANumber = async (SLANumber) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/reports/SLANumber`,
    SLANumber,
    {
      withCredentials: true,
    }
  );
};


// Bordereau Report
export const getBordereauReportData = async (searchFilters) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/bordereauReport/data`,
    {
      withCredentials: true,
      params: searchFilters
    }
  );
};

export const getBordereauReportRegistryCount = async (searchFilters) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/bordereauReport/count`,
    {
      withCredentials: true,
      params: searchFilters
    }
  );
};

export const getBordereauReportSelects = async () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/reports/bordereauReport/selects`,
    {
      withCredentials: true
    }
  );
};

// Global endpoints

export const insertVerifiedAgents = async (selectedRowKeys) => {
  return axios.post(
    `https://flowflood.westus3.cloudapp.azure.com/microservices/dev/databaseRequests/insertVerifiedAgents`,
    selectedRowKeys,
    {
      withCredentials: true,
    }
  );
};

export const postExcelDownload = async (searchFilters, reportName) => {

  let reports = {
    activityReport: {
      name: 'Activity Report'
    },
    bordereauReport: {
      name: 'Bordereau Report'
    }
    ,
    slanumber: {
      name: 'SLANumber Report'
    }
  }

  await axios({
    url: `${process.env.REACT_APP_BASE_URL}/reports/${reportName}/excel`, //your url
    method: 'GET',
    params: searchFilters,
    responseType: 'blob', // important
  }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${reports[reportName].name} - Data Export.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  });

  return 'ok'
};


export const { } = slice.actions

export default slice.reducer
