import React from "react";
import { Navbar } from "../../Layouts/Navbar";
import SLANumberComponentWip from "./SLANumberWip.jsx";
import SLANumberComponent from "./SLANumber.jsx";

const env = "production";

function StandardPopup() {
  return (
    <div className="standard_popup" id="standard_popup">
      <div className="standard_popup-header">
        <div
          className="title"
          style={{ width: "50%" }}
          id="title_standard_popup"
        ></div>
        {/* <button onClick={() => closestandard_popup()} className="close-button">&times;</button> */}
      </div>
      <div className="standard_popup-body" id="standard_popup_body"></div>
    </div>
  );
}

function Overlay() {
  return <div id="overlay"></div>;
}

function Scripts() {
  return (
    <>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/helper.js`}
      ></script>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/main.js`}
      ></script>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/pop-up-lib.js`}
      ></script>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/pop-ups.js`}
      ></script>
    </>
  );
}


export function SLANumberWip() {
  return (
    <>
      <Navbar />
      <SLANumberComponentWip />
      <StandardPopup />
      <Overlay />
      <Scripts />
    </>
  );
}
export function SLANumber() {
  return (
    <>
      <Navbar />
      <SLANumberComponent />
      <StandardPopup />
      <Overlay />
      <Scripts />
    </>
  );
}