import {
  // Spin,
  // Divider,
  // Checkbox,
  Typography,
  Layout,
  Breadcrumb,
  theme,
  Space,
  Table,
  Tag,
  Modal,
  Input,
  Form,
  DatePicker,
  Button,
  Select,
  Divider,
  Checkbox, Radio, ConfigProvider, Popover, message,
  Tooltip
} from "antd";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  RightOutlined,
  HomeOutlined,
  FileExcelOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  PlusOutlined,
  SlidersOutlined
} from "@ant-design/icons";
import dayjs from 'dayjs';
import customStyle from './style.css'
import { getActivityReportData, getActivityReportRegistryCount, getActivityReportSelects, insertVerifiedAgents, getBordereauReportSelects, postExcelDownload } from "../../redux/slice";
// import { getActivityReportData } from "./Scripts/Requests";
import { getUserData } from "../../Layouts/Navbar/Requests";

const { Title } = Typography;
const { Content, Footer } = Layout;
const { RangePicker } = DatePicker;

function MainSection() {

  // Data loading
  const [tableData, setTableData] = useState([]);
  const [registryCount, setRegistryCount] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSelectLoading, setSelectLoading] = useState(true);
  const [isTableLoading, setTableLoading] = useState(true);
  const [searchFilters, setSearchFilters] = useState({ quoteDate: [dayjs(new Date()).subtract(1, 'day'), dayjs(new Date())], siteEnvironment: 'Live' });
  const [selectData, setSelectData] = useState([]);

  const [selectUmrData, setSelectUmrData] = useState([]);

  const [userData, setUserData] = useState([]);
  const [hasDownloadPermission, setDownloadPermission] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState({
    "SL Tax Filled": [],
    "Commission Reconciled": [],
    "NB Audit": [],
    "Renewal Generated": [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();

        setUserData(data);

        const hasDownloadPermission = data.navBarItems.some(item => {
          return item.children && item.children.some(child => child.name === 'report.download');
        });

        setDownloadPermission(hasDownloadPermission)

      } catch (error) {
        console.log("Error:", error.message);
      }
    };
    fetchData();
  }, []);


  const [selectedRowCount, setSelectedRowCount] = useState(0)

  // Modal
  // const [modal, contextHolder] = Modal.useModal();

  // WINDOW SIZE LISTENER

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Checkboxes

  // SAVE CHECKED BOXES TO VERIFIEDAGENTS DATA BASE
  const handleApprove = async () => {

    await insertVerifiedAgents(selectedRowKeys);

    // Resetting variables to default
    setSelectedRowKeys({
      "SL Tax Filled": [],
      "Commission Reconciled": [],
      "NB Audit": [],
      "Renewal Generated": [],
    })

    setSelectedRowCount(0)

    getTableData(0)
    fillSelects()
    getPageCount()
    setCurrentOffset(0)
    setCurrentPage(1)

  };

  async function getTableData(pOffset) {

    setTableLoading(true);

    let mSearchFilters = searchFilters
    mSearchFilters.offset = pOffset
    setCurrentOffset(pOffset)
    const activityReportData = await getActivityReportData(mSearchFilters);

    // console.log('activity report data : ' + JSON.stringify(activityReportData.data));
    setTableData(activityReportData?.data?.searchResult);

    // SET UMR SELECTS DATA

    const umrData = activityReportData?.data?.searchResult;
    if (umrData) {
      const nonNullUMRs = umrData
        .filter(element => element.UMR !== null)
        .map(element => element.UMR)
        .filter(umr => umr !== null);

      const uniqueNonNullUMRs = [...new Set(nonNullUMRs)];

      console.log(uniqueNonNullUMRs);
      setSelectUmrData(uniqueNonNullUMRs)
    }

    console.log(activityReportData?.data?.searchResult)

    setTableLoading(false);

  }

  async function fillSelects() {
    const activityReportSelects = await getActivityReportSelects();
    // console.log(activityReportSelects?.data?.selects)
    setSelectData(activityReportSelects?.data?.selects);

    // console.log(activityReportSelects?.data?.selects.mDistinctSaleStates.map(saleState => ({
    //   value: saleState,
    //   label: saleState.saleStage
    // })))

    setSelectLoading(false);

  }

  async function getPageCount() {
    const activityReportRegistryCount = await getActivityReportRegistryCount(searchFilters);
    setRegistryCount(activityReportRegistryCount?.data?.count);
  }


  // Poblate data on web load
  useEffect(() => {
    Promise.all([fillSelects(), getPageCount(), getTableData(0)])
  }, []);

  // Seach filter useEffect
  const isFirstRender = useRef(true);

  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      // setLoading(true);
      setTableLoading(true);
      getPageCount();
      getTableData(0);
      // setLoading(false);
    }

  }, [searchFilters]);

  // Table row selection
  const onSelectChange = useCallback((key, quoteRef, event) => {
    setSelectedRowKeys((prevSelectedRowKeys) => {
      const updatedSelectedRowKeys = { ...prevSelectedRowKeys };

      if (event.target.checked) {
        updatedSelectedRowKeys[key] = [...updatedSelectedRowKeys[key], quoteRef];
      } else {
        updatedSelectedRowKeys[key] = updatedSelectedRowKeys[key].filter((item) => item !== quoteRef);
      }

      return updatedSelectedRowKeys;
    });
  }, []);

  // Updating the number of selected row keys
  useEffect(() => {
    let totalQuotes = [];
    for (let mKey of Object.keys(selectedRowKeys)) {
      totalQuotes = totalQuotes.concat(selectedRowKeys[mKey]);
    }

    let uniqueQuotes = [...new Set(totalQuotes)];
    console.log(uniqueQuotes)
    setSelectedRowCount(uniqueQuotes.length);
  }, [selectedRowKeys]);

  // Modal Handling
  const mainTable = useRef(null);

  const handlePageChange = (e) => {
    setTableLoading(true)
    setCurrentPage(e.current)
    getTableData((e.current - 1) * 100)
  }

  const columns = [
    {
      title: <Tooltip title="SL Tax Filled">SL Tax</Tooltip>,
      dataIndex: "SL Tax Filled",
      key: "SL Tax Filled",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('SL Tax Filled', row.Ref, e)}
          // onChange={(e) => console.log(row)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['SL Tax Filled'].includes(row.Ref)}
        />
      )
    },
    {
      title: <Tooltip title="Commission Reconciled">C. R.</Tooltip>,
      dataIndex: "Commission Reconciled",
      key: "Commission Reconciled",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('Commission Reconciled', row.Ref, e)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['Commission Reconciled'].includes(row.Ref)}
        />
      )
    },
    {
      title: <Tooltip title="NB Audit">NB Audit</Tooltip>,
      dataIndex: "NB Audit",
      key: "NB Audit",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('NB Audit', row.Ref, e)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['NB Audit'].includes(row.Ref)}
        />
      )
    },
    {
      title: <Tooltip title="Renewal Generated">Ren. Gen.</Tooltip>,
      dataIndex: "Renewal Generated",
      key: "Renewal Generated",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('Renewal Generated', row.Ref, e)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['Renewal Generated'].includes(row.Ref)}
        />
      )
    },
    {
      title: "Agent Group Ref",
      dataIndex: "Agent Group Ref",
      key: "Agent Group Ref"
    },
    {
      title: "PolicyNumberSuffixed",
      dataIndex: "PolicyNumberSuffixed",
      key: "PolicyNumberSuffixed"
    }, {
      title: "OriginatedFrom",
      dataIndex: "OriginatedFrom",
      key: "OriginatedFrom"
    }, {
      title: "Quote Ref",
      dataIndex: "Ref",
      key: "Ref"
    }, {
      title: "Created",
      dataIndex: "Created",
      key: "Created"
    }, {
      title: "Effective",
      dataIndex: "Effective",
      key: "Effective"
    }, {
      title: "Agency",
      dataIndex: "Agency",
      key: "Agency"
    }, {
      title: "Original Agent",
      dataIndex: "Original Agent",
      key: "Original Agent"
    }, {
      title: "Original Agent Email",
      dataIndex: "Original Agent Email",
      key: "Original Agent Email"
    }, {
      title: "Latest Agent",
      dataIndex: "Latest Agent",
      key: "Latest Agent"
    }, {
      title: "Stage",
      dataIndex: "Stage",
      key: "Stage"
    }, {
      title: "Referred",
      dataIndex: "Referred",
      key: "Referred"
    }, {
      title: "Declined (rule)",
      dataIndex: "Declined (rule)",
      key: "Declined (rule)"
    }, {
      title: "Address",
      dataIndex: "Address",
      key: "Address"
    }, {
      title: "Street Address",
      dataIndex: "Street Address",
      key: "Street Address"
    }, {
      title: "Address 2",
      dataIndex: "Address 2",
      key: "Address 2"
    }, {
      title: "City",
      dataIndex: "City",
      key: "City"
    }, {
      title: "State",
      dataIndex: "State",
      key: "State"
    }, {
      title: "Zip",
      dataIndex: "Zip",
      key: "Zip"
    }, {
      title: "County",
      dataIndex: "County",
      key: "County"
    }, {
      title: "Insured",
      dataIndex: "Insured",
      key: "Insured"
    }, {
      title: "Policy #",
      dataIndex: "Policy #",
      key: "Policy #"
    }, {
      title: "Zone",
      dataIndex: "Zone",
      key: "Zone"
    }, {
      title: "Lat",
      dataIndex: "Lat",
      key: "Lat"
    }, {
      title: "Long",
      dataIndex: "Long",
      key: "Long"
    }, {
      title: "Basement",
      dataIndex: "Basement",
      key: "Basement"
    }, {
      title: "Foundation Elevation",
      dataIndex: "Foundation Elevation",
      key: "Foundation Elevation"
    }, {
      title: "Tier",
      dataIndex: "Tier",
      key: "Tier"
    }, {
      title: "Chubb Score",
      dataIndex: "Chubb Score",
      key: "Chubb Score"
    }, {
      title: "Chaucer Score",
      dataIndex: "Chaucer Score",
      key: "Chaucer Score"
    }, {
      title: "Quote Selected",
      dataIndex: "Quote Selected",
      key: "Quote Selected"
    }, {
      title: "Pure Premium",
      dataIndex: "Pure Premium",
      key: "Pure Premium"
    }, {
      title: "Total Premium",
      dataIndex: "Total Premium",
      key: "Total Premium"
    }, {
      title: "Prior Policy - Total Premium",
      dataIndex: "Prior Policy - Total Premium",
      key: "Prior Policy - Total Premium"
    }, {
      title: "NFIP Premium",
      dataIndex: "NFIP Premium",
      key: "NFIP Premium"
    }, {
      title: "HI Dwel Prem",
      dataIndex: "HI Dwel Prem",
      key: "HI Dwel Prem"
    }, {
      title: "HI Combined Prem",
      dataIndex: "HI Combined Prem",
      key: "HI Combined Prem"
    }, {
      title: "HI Full Prem",
      dataIndex: "HI Full Prem",
      key: "HI Full Prem"
    }, {
      title: "Chubb Premium",
      dataIndex: "Chubb Premium",
      key: "Chubb Premium"
    }, {
      title: "Chaucer Premium",
      dataIndex: "Chaucer Premium",
      key: "Chaucer Premium"
    }, {
      title: "Vave Premium",
      dataIndex: "Vave Premium",
      key: "Vave Premium"
    }, {
      title: "Dwelling Replacement",
      dataIndex: "Dwelling Replacement",
      key: "Dwelling Replacement"
    }, {
      title: "Requested Dwelling",
      dataIndex: "Requested Dwelling",
      key: "Requested Dwelling"
    }, {
      title: "Requested Personal Property",
      dataIndex: "Requested Personal Property",
      key: "Requested Personal Property"
    }, {
      title: "Requested Loss of Use",
      dataIndex: "Requested Loss of Use",
      key: "Requested Loss of Use"
    }, {
      title: "NFIP Dwelling",
      dataIndex: "NFIP Dwelling",
      key: "NFIP Dwelling"
    }, {
      title: "NFIP Personal Property",
      dataIndex: "NFIP Personal Property",
      key: "NFIP Personal Property"
    }, {
      title: "Hiscox Dwelling",
      dataIndex: "Hiscox Dwelling",
      key: "Hiscox Dwelling"
    }, {
      title: "Hiscox Quote ID",
      dataIndex: "Hiscox Quote ID",
      key: "Hiscox Quote ID"
    }, {
      title: "Chubb TIV",
      dataIndex: "Chubb TIV",
      key: "Chubb TIV"
    }, {
      title: "Chubb Dwelling RCV",
      dataIndex: "Chubb Dwelling RCV",
      key: "Chubb Dwelling RCV"
    }, {
      title: "Chubb Dwelling Cov",
      dataIndex: "Chubb Dwelling Cov",
      key: "Chubb Dwelling Cov"
    }, {
      title: "Chubb Per Prop RCV",
      dataIndex: "Chubb Per Prop RCV",
      key: "Chubb Per Prop RCV"
    }, {
      title: "Chubb Per Prop Cov",
      dataIndex: "Chubb Per Prop Cov",
      key: "Chubb Per Prop Cov"
    }, {
      title: "Chubb Loss of Use",
      dataIndex: "Chubb Loss of Use",
      key: "Chubb Loss of Use"
    }, {
      title: "Chubb Limit Type",
      dataIndex: "Chubb Limit Type",
      key: "Chubb Limit Type"
    }, {
      title: "Chauver TIV",
      dataIndex: "Chauver TIV",
      key: "Chauver TIV"
    }, {
      title: "Chaucer Dwelling RCV",
      dataIndex: "Chaucer Dwelling RCV",
      key: "Chaucer Dwelling RCV"
    }, {
      title: "Chaucer Dwelling Cov",
      dataIndex: "Chaucer Dwelling Cov",
      key: "Chaucer Dwelling Cov"
    }, {
      title: "Chaucer Per Prop Cov",
      dataIndex: "Chaucer Per Prop Cov",
      key: "Chaucer Per Prop Cov"
    }, {
      title: "Chaucer Loss of Use",
      dataIndex: "Chaucer Loss of Use",
      key: "Chaucer Loss of Use"
    }, {
      title: "Hiscox Dwelling RCV",
      dataIndex: "Hiscox Dwelling RCV",
      key: "Hiscox Dwelling RCV"
    }, {
      title: "Hiscox Dwelling Cov",
      dataIndex: "Hiscox Dwelling Cov",
      key: "Hiscox Dwelling Cov"
    }, {
      title: "Hiscox Per Prop RCV",
      dataIndex: "Hiscox Per Prop RCV",
      key: "Hiscox Per Prop RCV"
    }, {
      title: "Hiscox Per Prop Cov",
      dataIndex: "Hiscox Per Prop Cov",
      key: "Hiscox Per Prop Cov"
    }, {
      title: "Vave Dwelling RCV",
      dataIndex: "Vave Dwelling RCV",
      key: "Vave Dwelling RCV"
    }, {
      title: "Vave Dwelling Cov",
      dataIndex: "Vave Dwelling Cov",
      key: "Vave Dwelling Cov"
    }, {
      title: "Vave Pers Prop RCV",
      dataIndex: "Vave Pers Prop RCV",
      key: "Vave Pers Prop RCV"
    }, {
      title: "Vave Pers Prop Cov",
      dataIndex: "Vave Pers Prop Cov",
      key: "Vave Pers Prop Cov"
    }, {
      title: "Hiscox Ref ID",
      dataIndex: "Hiscox Ref ID",
      key: "Hiscox Ref ID"
    }, {
      title: "Quote Date",
      dataIndex: "Quote Date",
      key: "Quote Date"
    }, {
      title: "Argo Premium",
      dataIndex: "Argo Premium",
      key: "Argo Premium"
    }, {
      title: "Selected Limit",
      dataIndex: "Selected Limit",
      key: "Selected Limit"
    }, {
      title: "Selected Pers Prop",
      dataIndex: "Selected Pers Prop",
      key: "Selected Pers Prop"
    }, {
      title: "Selected LOU",
      dataIndex: "Selected LOU",
      key: "Selected LOU"
    }, {
      title: "Selected Other Struct",
      dataIndex: "Selected Other Struct",
      key: "Selected Other Struct"
    }, {
      title: "Selected Dwelling Deduct",
      dataIndex: "Selected Dwelling Deduct",
      key: "Selected Dwelling Deduct"
    }, {
      title: "Selected Pers Prop Deduct",
      dataIndex: "Selected Pers Prop Deduct",
      key: "Selected Pers Prop Deduct"
    }, {
      title: "Selected Dwelling Replacement",
      dataIndex: "Selected Dwelling Replacement",
      key: "Selected Dwelling Replacement"
    }, {
      title: "Selected Pers Prop replacement",
      dataIndex: "Selected Pers Prop replacement",
      key: "Selected Pers Prop replacement"
    }, {
      title: "TIV",
      dataIndex: "TIV",
      key: "TIV"
    }, {
      title: "Occupancy",
      dataIndex: "Occupancy",
      key: "Occupancy"
    }, {
      title: "Foundation Type",
      dataIndex: "Foundation Type",
      key: "Foundation Type"
    }, {
      title: "Foundation Category",
      dataIndex: "Foundation Category",
      key: "Foundation Category"
    }, {
      title: "Sq/Ft",
      dataIndex: "Sq/Ft",
      key: "Sq/Ft"
    }, {
      title: "Construction Type",
      dataIndex: "Construction Type",
      key: "Construction Type"
    }, {
      title: "Dwelling Type",
      dataIndex: "Dwelling Type",
      key: "Dwelling Type"
    }, {
      title: "Year Built",
      dataIndex: "Year Built",
      key: "Year Built"
    }, {
      title: "Stories",
      dataIndex: "Stories",
      key: "Stories"
    }, {
      title: "Losses",
      dataIndex: "Losses",
      key: "Losses"
    }, {
      title: "Vave Ref ID",
      dataIndex: "Vave Ref ID",
      key: "Vave Ref ID"
    }, {
      title: "order_transactiontype",
      dataIndex: "order_transactiontype",
      key: "order_transactiontype"
    }, {
      title: "TransactionType",
      dataIndex: "TransactionType",
      key: "TransactionType"
    }, {
      title: "New-Renewal",
      dataIndex: "New-Renewal",
      key: "New-Renewal"
    }, {
      title: "SiteEnvironment",
      dataIndex: "SiteEnvironment",
      key: "SiteEnvironment"
    }, {
      title: "Ground Elevation",
      dataIndex: "Ground Elevation",
      key: "Ground Elevation"
    }, {
      title: "BFE",
      dataIndex: "BFE",
      key: "BFE"
    }, {
      title: "COBRA Zone",
      dataIndex: "COBRA Zone",
      key: "COBRA Zone"
    }, {
      title: "Surge",
      dataIndex: "Surge",
      key: "Surge"
    }, {
      title: "Surge Elligible - Brit",
      dataIndex: "Surge Elligible - Brit",
      key: "Surge Elligible - Brit"
    }, {
      title: "Surge Lookup",
      dataIndex: "Surge Lookup",
      key: "Surge Lookup"
    }, {
      title: "Non-Surge Lookup",
      dataIndex: "Non-Surge Lookup",
      key: "Non-Surge Lookup"
    }, {
      title: "Chaucer UW Rule",
      dataIndex: "Chaucer UW Rule",
      key: "Chaucer UW Rule"
    }, {
      title: "Brit UW Rule",
      dataIndex: "Brit UW Rule",
      key: "Brit UW Rule"
    }, {
      title: "Brit Score",
      dataIndex: "Brit Score",
      key: "Brit Score"
    }, {
      title: "Brit Premium",
      dataIndex: "Brit Premium",
      key: "Brit Premium"
    }, {
      title: "Vave UW Rule",
      dataIndex: "Vave UW Rule",
      key: "Vave UW Rule"
    }, {
      title: "Argo UW Rule",
      dataIndex: "Argo UW Rule",
      key: "Argo UW Rule"
    }, {
      title: "Chubb UW Rule",
      dataIndex: "Chubb UW Rule",
      key: "Chubb UW Rule"
    }, {
      title: "Hiscox UW Rukle",
      dataIndex: "Hiscox UW Rukle",
      key: "Hiscox UW Rukle"
    }, {
      title: "Argo - Restricted Zip",
      dataIndex: "Argo - Restricted Zip",
      key: "Argo - Restricted Zip"
    }, {
      title: "Brit - Restricted Zip",
      dataIndex: "Brit - Restricted Zip",
      key: "Brit - Restricted Zip"
    }, {
      title: "Argo - Restricted County",
      dataIndex: "Argo - Restricted County",
      key: "Argo - Restricted County"
    }, {
      title: "Brit - Restricted County",
      dataIndex: "Brit - Restricted County",
      key: "Brit - Restricted County"
    }, {
      title: "Chaucer - Restricted County",
      dataIndex: "Chaucer - Restricted County",
      key: "Chaucer - Restricted County"
    }, {
      title: "Chubb - Restricted County",
      dataIndex: "Chubb - Restricted County",
      key: "Chubb - Restricted County"
    }, {
      title: "Hiscox - Restricted County",
      dataIndex: "Hiscox - Restricted County",
      key: "Hiscox - Restricted County"
    }, {
      title: "Vave - Restricted County",
      dataIndex: "Vave - Restricted County",
      key: "Vave - Restricted County"
    }, {
      title: "Binding Moratorium",
      dataIndex: "Binding Moratorium",
      key: "Binding Moratorium"
    },
    {
      title: "County Decline",
      dataIndex: "County Decline",
      key: "County Decline"
    },
    {
      title: "UMR",
      dataIndex: "UMR",
      key: "UMR"
    },
    {
      title: "updateDate",
      dataIndex: "updateDate",
      key: "updateDate"
    }
  ];

  // Search filters
  const onSearchSubmit = (values) => {
    console.log(values)
    setTableLoading(true)
    setCurrentPage(1)
    setCurrentOffset(0)
    setSearchFilters(values)
    
    setSelectedRowKeys({
      "SL Tax Filled": [],
      "Commission Reconciled": [],
      "NB Audit": [],
      "Renewal Generated": [],
    })
    // getTableData(0);
  };

  // GET ACTIVITY REPORT AS EXCEL FILE
  const [messageApi, contextHolder] = message.useMessage();

  const handleExelActivityReport = async () => {
    setTableLoading(true)
    messageApi.open({
      key: 'updatable',
      type: 'loading',
      content: 'Generating Excel File...',
      duration: 0,
    })

    await postExcelDownload(searchFilters, 'activityReport')

    !isTableLoading && (messageApi.open({
      key: 'updatable',
      type: 'success',
      content: 'Excel File Ready.',
      duration: 5,
    }))

    setTableLoading(false)
  }

  return (
    <section className="main">
      <Content style={{ padding: "0 50px" }}>
        <div style={{ padding: "20px", borderRadius: "20px" }}>

          <Breadcrumb separator={<RightOutlined />} style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
            <Breadcrumb.Item>Activity</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Typography>
              <Title>Activity Report</Title>
            </Typography>

            <ConfigProvider theme={{ token: { colorPrimary: '#1d6f42', "borderRadius": 10, "fontSize": 14.5 } }}>

              {hasDownloadPermission && (
                <Button
                  disabled={isTableLoading}
                  onClick={() => handleExelActivityReport()}
                  type='primary'
                  size='large'
                >
                  Download Excel <FileExcelOutlined />
                </Button>
              )}

            </ConfigProvider>
          </div>

          <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, height: 'fit-content' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ backgroundColor: '#f5f5f5', paddingLeft: 20, paddingTop: 20, paddingRight: 20, marginRight: 10, borderRadius: 10, height: 'fit-content' }}>

                <div style={{ marginBottom: 20 }}>
                  <Title level={4}><SlidersOutlined /> Filters</Title>

                </div>
                {/* <Title level={5}>Filters</Title> */}

                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onSearchSubmit}
                  style={{ maxWidth: 400, minWidth: 400 }}
                  initialValues={searchFilters}
                  autoComplete="off"
                >

                  <Form.Item
                    name="createdDate"
                    label="Created Date"
                    style={{
                      width: '100%',
                    }}
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value || getFieldValue("effectiveDate") || getFieldValue("quoteDate")) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("At least one date field is required."));
                        },
                      }),
                    ]}
                  >
                    <RangePicker
                      style={{
                        width: '100%',
                      }}
                      disabled={isSelectLoading} />
                  </Form.Item>

                  <Form.Item
                    name="effectiveDate"
                    label="Effective Date"
                    style={{
                      width: '100%',
                    }}
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value || getFieldValue("createdDate") || getFieldValue("quoteDate")) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("At least one date field is required."));
                        },
                      }),
                    ]}
                  >
                    <RangePicker
                      style={{
                        width: '100%',
                      }} disabled={isSelectLoading} />
                  </Form.Item>

                  <Form.Item
                    name="quoteDate"
                    label="Quote Date"
                    style={{
                      width: '100%',
                    }}
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value || getFieldValue("createdDate") || getFieldValue("effectiveDate")) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("At least one date field is required."));
                        },
                      }),
                    ]}
                  >
                    <RangePicker
                      style={{
                        width: '100%',
                      }}
                      disabled={isSelectLoading} />
                  </Form.Item>

                  <Divider />

                  <Form.Item
                    name="verifiedCheckboxes"
                    label=""
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          value: 'sltaxfilled',
                          label: 'SL Tax Filled',
                        },
                        {
                          value: 'commissionreconciled',
                          label: 'Commission Reconciled',
                        },
                        {
                          value: 'nbaudit',
                          label: 'NB Audit',
                        },
                        {
                          value: 'renewalgenerated',
                          label: 'Renewal Generated',
                        },
                      ]}
                      disabled={isSelectLoading}
                      // style={{ display: 'flex', flexDirection: 'column' }}
                    />
                  </Form.Item>


                  <Divider />

                  <Form.Item
                    name="Ref"
                    label="Quote Ref"
                  >

                    <Select
                      disabled={isSelectLoading}
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      tokenSeparators={[',']}
                    />

                  </Form.Item>
                  <Typography style={{ color: 'gray', marginTop: -20 }}>Input Quotes separated by coma ( , )</Typography>

                  <br />

                  <Form.Item
                    name="PolicyNumber"
                    label="PolicyNumber"
                  >

                    <Select
                      disabled={isSelectLoading}
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      tokenSeparators={[',']}
                    />
                  </Form.Item>
                  <Typography style={{ color: 'gray', marginTop: -20 }}>Input policies separated by coma ( , )</Typography>

                  <br />

                  {/* <Form.Item
                    name="UMR"
                    label="UMR"
                  >

                    <Select
                      mode="tags"
                      style={{
                        width: '100%',
                      }}
                      tokenSeparators={[',']}
                    />
                  </Form.Item>
                  <Typography style={{ color: 'gray', marginTop: -20 }}>Input UMR separated by coma ( , )</Typography>

                  <Divider />

                  <Form.Item
                    name="stage"
                    label="Stage"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select an stage"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctSaleStates.map(saleState => ({
                        value: saleState.selectValues,
                        label: saleState.selectValues
                      })) : []}
                    />
                  </Form.Item> */}

                  <Form.Item
                    name="UMR"
                    label="UMR"
                    mode="multiple"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      placeholder="Select an UMR"
                      optionFilterProp="children"
                      mode="multiple"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectUmrData?.map(umr => ({
                        value: umr,
                        label: umr
                      })) : []}
                    />

                  </Form.Item>

                  <Form.Item
                    name="new-renewal"
                    label="New-Renewal"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select an state"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: 'new',
                          label: 'New',
                        },
                        {
                          value: 'renewal',
                          label: 'Renewal',
                        }
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="state"
                    label="State"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select an state"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctStates.map(state => ({
                        value: state.selectValues,
                        label: state.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="originated-from"
                    label="Originated From"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Select an originated from"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctOriginatedFrom.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="agency"
                    label="Agency"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Select an originated from"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctAgency.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="originalAgent"
                    label="Original Agent"
                    mode="multiple"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      placeholder="Select an original agent name"
                      optionFilterProp="children"
                      mode="multiple"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctOriginalAgent.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="transactionType"
                    label="Transaction Type"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      placeholder="Select a transaction type"
                      optionFilterProp="children"
                      mode="multiple"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctTransactionType.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="stage"
                    label="Sale Stage"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Select an originated from"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctSaleStates.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Divider />

                  <div style={{ display: 'flex', width: '100%', 'justifyContent': 'space-between' }}>
                    <Form.Item
                      name="referred"
                      label="Referred"
                      hasFeedback
                      validateStatus={!isSelectLoading ? "" : "validating"}
                    >
                      <Select
                        style={{
                          width: '100%',
                          minWidth: 100
                        }}
                        showSearch
                        placeholder=""
                        optionFilterProp="children"
                        mode="multiple"
                        disabled={isSelectLoading}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={!isSelectLoading ? selectData.mDistinctReferred.map(a => ({
                          value: a.selectValues,
                          label: a.selectValues
                        })) : []}
                      />
                    </Form.Item>

                    <Form.Item
                      name="siteEnvironment"
                      label="Site Environment"
                      hasFeedback
                      validateStatus={!isSelectLoading ? "" : "validating"}
                    >
                      <Select
                        style={{
                          width: '100%',
                          minWidth: 100
                        }}
                        showSearch
                        placeholder="Select an enviroment"
                        optionFilterProp="children"
                        disabled={isSelectLoading}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        // defaultValue={{ value: 'Live', label: 'Live' }}
                        options={[
                          {
                            value: 'Live',
                            label: 'Live',
                          },
                          {
                            value: 'Test',
                            label: 'Test',
                          }
                        ]}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item>
                    <Button type="primary" size='large' style={{ width: '100%' }} disabled={isSelectLoading || isTableLoading} htmlType="submit">
                      <SearchOutlined /> Search
                    </Button>
                  </Form.Item>

                </Form>
              </div>

              <div style={{ overflowX: "auto", marginLeft: 10, height: 'fit-content' }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#194FA2',
                      colorPrimaryBg: 'ffffff',
                      // colorBorder: '#00B0B0',
                      "borderRadius": 10,
                      "fontSize": 14.5,
                      // "fontFamily": 'Poppins',
                      // 'colorPrimaryHover': '#00B0B0'
                    }
                  }}
                >
                  <Table
                    rowClassName={(record, index) => (record.verified == 'true' && 'green')}
                    loading={isTableLoading}
                    ref={mainTable}
                    rowKey={(record) => record.Ref}
                    columns={columns.map(column => {
                      return { width: 200, ...column };
                    })}
                    dataSource={tableData}
                    onChange={(e) => handlePageChange(e)}
                    footer={(currentTable) => `Showing ${currentOffset} - ${currentOffset + currentTable.length} of ${registryCount} total`}
                    pagination={{
                      current: currentPage,
                      position: ["bottomCenter"],
                      defaultCurrent: 1,
                      pageSize: 100,
                      total: registryCount,
                      showSizeChanger: false,
                    }}
                    // scroll={{ y: '100%' }}
                    scroll={{ y: windowSize.height }}
                  />
                </ConfigProvider>
              </div>

            </div>

            <div
              style={{
                position: "fixed",
                bottom: 5,
                right: 5,
                margin: "1.5em"
              }}
            >
              {selectedRowCount != 0 && (
                <div>
                  {/* <Button
                    type="dashed"
                    size={"large"}
                    style={
                      { boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', margin: 10, width: 200 }}
                    onClick={() => handleClearSelection()}
                    loading={isSelectLoading}
                  >
                    {'Clear Selection '}
                  </Button> */}
                  <Button
                    type="primary"
                    size={"large"}
                    style={
                      // !hasSelected
                      //   ? { transform: "scale(0)" }
                      //   : { marginRight: 12, transform: "scale(1)" }
                      { boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', margin: 0, width: 150 }
                    }
                    onClick={() => handleApprove()}
                    loading={isSelectLoading}
                  >
                    {'Verify ' + selectedRowCount + (selectedRowCount == 1 ? ' row' : ' rows')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {selectUmrData && console.log(selectUmrData)}
        {contextHolder}
      </Content>
    </section>
  );
}

export default MainSection;
