import React, { useState } from "react";
import Login from "./Login";

export function LoginSite() {
  return (
    <>
      <div
        style={{
          backgroundColor: "whitesmoke",
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      >
        <Login />
      </div>
    </>
  );
}
