import {
  Typography,
  Layout,
  Breadcrumb,
  Table,
  Form,
  DatePicker,
  Button,
  Select,
  Divider,
  Checkbox, Radio, ConfigProvider, Popover, message,
  Tooltip
} from "antd";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  SearchOutlined,
  RightOutlined,
  HomeOutlined,
  FileExcelOutlined,
  SlidersOutlined
} from "@ant-design/icons";
import dayjs from 'dayjs';
import customStyle from './style.css'

import { getBordereauReportData, getBordereauReportRegistryCount, getBordereauReportSelects, insertVerifiedAgents, postExcelDownload } from "../../redux/slice";
const { Title } = Typography;
const { Content, Footer } = Layout;
const { RangePicker } = DatePicker;
import { getUserData } from "../../Layouts/Navbar/Requests";

function MainSection() {

  // Data loading
  const [tableData, setTableData] = useState([]);
  const [registryCount, setRegistryCount] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSelectLoading, setSelectLoading] = useState(true);
  const [isTableLoading, setTableLoading] = useState(true);
  const [searchFilters, setSearchFilters] = useState({ saleDate: [dayjs(new Date()).subtract(1, 'day'), dayjs(new Date())] });
  const [selectData, setSelectData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState({
    "SL Tax Filled": [],
    "Commission Reconciled": [],
    "NB Audit": [],
    "Renewal Generated": [],
  });

  const [selectedRowCount, setSelectedRowCount] = useState(0)

  const [userData, setUserData] = useState([]);
  const [hasDownloadPermission, setDownloadPermission] = useState(false);


  // WINDOW SIZE LISTENER

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Checkboxes

  // SAVE CHECKED BOXES TO VERIFIEDAGENTS DATA BASE
  const handleApprove = async () => {

    await insertVerifiedAgents(selectedRowKeys);

    // Resetting variables to default
    setSelectedRowKeys({
      "SL Tax Filled": [],
      "Commission Reconciled": [],
      "NB Audit": [],
      "Renewal Generated": [],
    })

    setSelectedRowCount(0)

    getTableData(0)
    fillSelects()
    getPageCount()
    setCurrentOffset(0)
    setCurrentPage(1)

  };

  async function getTableData(pOffset) {

    setTableLoading(true);

    let mSearchFilters = searchFilters
    mSearchFilters.offset = pOffset
    setCurrentOffset(pOffset)
    const bordereauReportData = await getBordereauReportData(mSearchFilters);

    // console.log('bordereau report data : ' + JSON.stringify(bordereauReportData.data));
    setTableData(bordereauReportData?.data?.searchResult);
    console.log(bordereauReportData?.data?.searchResult)

    setTableLoading(false);

  }

  async function fillSelects() {
    const bordereauReportSelects = await getBordereauReportSelects();
    setSelectData(bordereauReportSelects?.data?.selects);
    setSelectLoading(false);
  }

  async function getPageCount() {
    const bordereauReportRegistryCount = await getBordereauReportRegistryCount(searchFilters);
    setRegistryCount(bordereauReportRegistryCount?.data?.count);
  }

  // Poblate data on web load
  useEffect(async () => {
    Promise.all([fillSelects(), getPageCount(), getTableData(0)])
  }, []);

  // Seach filter useEffect
  const isFirstRender = useRef(true);

  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setTableLoading(true);
      getPageCount();
      getTableData(0);
    }

  }, [searchFilters]);

  // Table row selection
  const onSelectChange = useCallback((key, quoteRef, event) => {
    setSelectedRowKeys((prevSelectedRowKeys) => {
      const updatedSelectedRowKeys = { ...prevSelectedRowKeys };

      if (event.target.checked) {
        updatedSelectedRowKeys[key] = [...updatedSelectedRowKeys[key], quoteRef];
      } else {
        updatedSelectedRowKeys[key] = updatedSelectedRowKeys[key].filter((item) => item !== quoteRef);
      }

      return updatedSelectedRowKeys;
    });
  }, []);

  // Updating the number of selected row keys
  useEffect(() => {
    let totalQuotes = [];
    for (let mKey of Object.keys(selectedRowKeys)) {
      totalQuotes = totalQuotes.concat(selectedRowKeys[mKey]);
    }

    let uniqueQuotes = [...new Set(totalQuotes)];
    console.log(uniqueQuotes)
    setSelectedRowCount(uniqueQuotes.length);
  }, [selectedRowKeys]);

  // Modal Handling
  const mainTable = useRef(null);

  const handlePageChange = (e) => {
    setTableLoading(true)
    setCurrentPage(e.current)
    getTableData((e.current - 1) * 100)
  }

  const columns = [
    {
      title: <Tooltip title="SL Tax Filled">SL Tax</Tooltip>,
      dataIndex: "SL Tax Filled",
      key: "SL Tax Filled",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('SL Tax Filled', row.Ref, e)}
          // onChange={(e) => console.log(row)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['SL Tax Filled'].includes(row.Ref)}
        />
      )
    },
    {
      title: <Tooltip title="Commission Reconciled">C. R.</Tooltip>,
      dataIndex: "Commission Reconciled",
      key: "Commission Reconciled",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('Commission Reconciled', row.Ref, e)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['Commission Reconciled'].includes(row.Ref)}
        />
      )
    },
    {
      title: <Tooltip title="NB Audit">NB Audit</Tooltip>,
      dataIndex: "NB Audit",
      key: "NB Audit",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('NB Audit', row.Ref, e)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['NB Audit'].includes(row.Ref)}
        />
      )
    },
    {
      title: <Tooltip title="Renewal Generated">Ren. Gen.</Tooltip>,
      dataIndex: "Renewal Generated",
      key: "Renewal Generated",
      align: "center",
      width: 80,
      render: (text, row, index) => (
        <Checkbox
          onChange={(e) => onSelectChange('Renewal Generated', row.Ref, e)}
          disabled={text}
          defaultChecked={text || selectedRowKeys['Renewal Generated'].includes(row.Ref)}
        />
      )
    },

    { title: "SaleStage", dataIndex: "SaleStage", key: "SaleStage" },
    { title: "QuoteSelected_TXT (X)", dataIndex: "QuoteSelected_TXT (X)", key: "QuoteSelected_TXT (X)" },
    { title: "Carrier", dataIndex: "Carrier", key: "Carrier" },
    { title: "Coverholder Name", dataIndex: "Coverholder Name", key: "Coverholder Name" },
    { title: "Coverholder PIN", dataIndex: "Coverholder PIN", key: "Coverholder PIN" },
    { title: "Unique Market Reference (UMR)", dataIndex: "Unique Market Reference (UMR)", key: "Unique Market Reference (UMR)" },
    { title: "Reporting Period Start Date", dataIndex: "Reporting Period Start Date", key: "Reporting Period Start Date" },
    { title: "Reporting Period (End Date)", dataIndex: "Reporting Period (End Date)", key: "Reporting Period (End Date)" },
    { title: "Section No", dataIndex: "Section No", key: "Section No" },
    { title: "Class of Business", dataIndex: "Class of Business", key: "Class of Business" },
    { title: "Risk Code", dataIndex: "Risk Code", key: "Risk Code" },
    { title: "Type of Insurance (Direct or Type or Reinsurance)", dataIndex: "Type of Insurance (Direct or Type or Reinsurance)", key: "Type of Insurance (Direct or Type or Reinsurance)" },
    { title: "Year of Account", dataIndex: "Year of Account", key: "Year of Account" },
    { title: "Certificate Ref", dataIndex: "Certificate Ref", key: "Certificate Ref" },
    { title: "Insured Full Name, Last Name or Company Name", dataIndex: "Insured Full Name, Last Name or Company Name", key: "Insured Full Name, Last Name or Company Name" },
    { title: "Insured State", dataIndex: "Insured State", key: "Insured State" },
    { title: "Insured Country", dataIndex: "Insured Country", key: "Insured Country" },
    { title: "Risk Inception Date", dataIndex: "Risk Inception Date", key: "Risk Inception Date" },
    { title: "Risk Expiry Date", dataIndex: "Risk Expiry Date", key: "Risk Expiry Date" },
    { title: "Location of risk - Country", dataIndex: "Location of risk - Country", key: "Location of risk - Country" },
    { title: "Location of Risk - State", dataIndex: "Location of Risk - State", key: "Location of Risk - State" },
    { title: "Transaction Type - Original Premium etc.", dataIndex: "Transaction Type - Original Premium etc.", key: "Transaction Type - Original Premium etc." },
    { title: "Effective Date of Transaction", dataIndex: "Effective Date of Transaction", key: "Effective Date of Transaction" },
    { title: "Original Currency", dataIndex: "Original Currency", key: "Original Currency" },
    { title: "Total Gross Written Premium", dataIndex: "Total Gross Written Premium", key: "Total Gross Written Premium" },
    { title: "Gross premium paid this time", dataIndex: "Gross premium paid this time", key: "Gross premium paid this time" },
    { title: "Commission %", dataIndex: "Commission %", key: "Commission %" },
    { title: "Commission Amount", dataIndex: "Commission Amount", key: "Commission Amount" },
    { title: "Net Premium to London in Original Currency", dataIndex: "Net Premium to London in Original Currency", key: "Net Premium to London in Original Currency" },
    { title: "Settlement Currency", dataIndex: "Settlement Currency", key: "Settlement Currency" },
    { title: "% of Net to London for CJ Coleman", dataIndex: "% of Net to London for CJ Coleman", key: "% of Net to London for CJ Coleman" },
    { title: "$ of Net Premium to London for CJ Coleman", dataIndex: "$ of Net Premium to London for CJ Coleman", key: "$ of Net Premium to London for CJ Coleman" },
    { title: "US Classification", dataIndex: "US Classification", key: "US Classification" },
    { title: "State of Filing (see code list)", dataIndex: "State of Filing (see code list)", key: "State of Filing (see code list)" },
    { title: "Surplus Lines Broker Name", dataIndex: "Surplus Lines Broker Name", key: "Surplus Lines Broker Name" },
    { title: "Surplus Lines Broker Licence No", dataIndex: "Surplus Lines Broker Licence No", key: "Surplus Lines Broker Licence No" },
    { title: "New Jersey SLA No", dataIndex: "New Jersey SLA No", key: "New Jersey SLA No" },
    { title: "Surplus Lines Broker Address", dataIndex: "Surplus Lines Broker Address", key: "Surplus Lines Broker Address" },
    { title: "Surplus Lines Broker State", dataIndex: "Surplus Lines Broker State", key: "Surplus Lines Broker State" },
    { title: "Surplus Lines Broker Zip Code", dataIndex: "Surplus Lines Broker Zip Code", key: "Surplus Lines Broker Zip Code" },
    { title: "Surplus Lines Broker Country", dataIndex: "Surplus Lines Broker Country", key: "Surplus Lines Broker Country" },
    { title: "Insured Address", dataIndex: "Insured Address", key: "Insured Address" },
    { title: "City", dataIndex: "City", key: "City" },
    { title: "Insured Postcode, Zip Code or Similar", dataIndex: "Insured Postcode, Zip Code or Similar", key: "Insured Postcode, Zip Code or Similar" },
    { title: "Address", dataIndex: "Address", key: "Address" },
    { title: "Location of Risk, City", dataIndex: "Location of Risk, City", key: "Location of Risk, City" },
    { title: "Location of Risk, County", dataIndex: "Location of Risk, County", key: "Location of Risk, County" },
    { title: "Location of Risk, Postcode, zip code or similar", dataIndex: "Location of Risk, Postcode, zip code or similar", key: "Location of Risk, Postcode, zip code or similar" },
    { title: "Country of Registration", dataIndex: "Country of Registration", key: "Country of Registration" },
    { title: "Sum Insured Currency (see code list)", dataIndex: "Sum Insured Currency (see code list)", key: "Sum Insured Currency (see code list)" },
    { title: "Sum Insured Amount", dataIndex: "Sum Insured Amount", key: "Sum Insured Amount" },
    { title: "SaleDate", dataIndex: "SaleDate", key: "SaleDate" },
    { title: "CreatedFrom", dataIndex: "CreatedFrom", key: "CreatedFrom" },
    { title: "OriginatedFrom", dataIndex: "OriginatedFrom", key: "OriginatedFrom" },
    { title: "Broker Fee", dataIndex: "Broker Fee", key: "Broker Fee" },
    { title: "Ref", dataIndex: "Ref", key: "Ref" },
    { title: "Insured Names Display", dataIndex: "Insured Names Display", key: "Insured Names Display" },
    { title: "EMPATax", dataIndex: "EMPATax", key: "EMPATax" },
    { title: "EMPATax Num", dataIndex: "EMPATax Num", key: "EMPATax Num" },
    { title: "Stamping Fee", dataIndex: "Stamping Fee", key: "Stamping Fee" },
    { title: "Stamping Fee Num", dataIndex: "Stamping Fee Num", key: "Stamping Fee Num" },
    { title: "SL Tax", dataIndex: "SL Tax", key: "SL Tax" },
    { title: "SL Tax Return Num", dataIndex: "SL Tax Return Num", key: "SL Tax Return Num" },
    { title: "SL Service Charge", dataIndex: "SL Service Charge", key: "SL Service Charge" },
    { title: "SL Service Charge Num", dataIndex: "SL Service Charge Num", key: "SL Service Charge Num" },
    { title: "Minicipal Fee", dataIndex: "Minicipal Fee", key: "Minicipal Fee" },
    { title: "Minicipal Fee Num", dataIndex: "Minicipal Fee Num", key: "Minicipal Fee Num" },
    { title: "FMTax", dataIndex: "FMTax", key: "FMTax" },
    { title: "FMTax Num", dataIndex: "FMTax Num", key: "FMTax Num" },
    { title: "BDX Client", dataIndex: "BDX Client", key: "BDX Client" },
    { title: "Policy Change Effective", dataIndex: "Policy Change Effective", key: "Policy Change Effective" },
    { title: "Property Change", dataIndex: "Property Change", key: "Property Change" },
    { title: "Name and Contact Change", dataIndex: "Name and Contact Change", key: "Name and Contact Change" },
    { title: "Mortgagee Change", dataIndex: "Mortgagee Change", key: "Mortgagee Change" },
    { title: "Mailing Address Change", dataIndex: "Mailing Address Change", key: "Mailing Address Change" },
    { title: "Flood Zone Change", dataIndex: "Flood Zone Change", key: "Flood Zone Change" },
    { title: "Coverage and Deductible Change", dataIndex: "Coverage and Deductible Change", key: "Coverage and Deductible Change" },
    { title: "Billing Change", dataIndex: "Billing Change", key: "Billing Change" },
    { title: "Other Policy Change", dataIndex: "Other Policy Change", key: "Other Policy Change" },
    { title: "Requested Policy Change", dataIndex: "Requested Policy Change", key: "Requested Policy Change" }
  ];

  // Search filters
  const onSearchSubmit = (values) => {
    setTableLoading(true)
    setCurrentPage(1)
    setCurrentOffset(0)
    setSearchFilters(values)
    setSelectedRowKeys({
      "SL Tax Filled": [],
      "Commission Reconciled": [],
      "NB Audit": [],
      "Renewal Generated": [],

    })
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();

        setUserData(data);

        const hasDownloadPermission = data.navBarItems.some(item => {
          return item.children && item.children.some(child => child.name === 'report.download');
        });

        setDownloadPermission(hasDownloadPermission)

      } catch (error) {
        console.log("Error:", error.message);
      }
    };
    fetchData();
  }, []);


  // GET ACTIVITY REPORT AS EXCEL FILE
  const [messageApi, contextHolder] = message.useMessage();

  const handleExelBordereauReport = async () => {
    setTableLoading(true)
    messageApi.open({
      key: 'updatable',
      type: 'loading',
      content: 'Generating Excel File...',
      duration: 0,
    })

    await postExcelDownload(searchFilters, 'bordereauReport')

    !isTableLoading && (messageApi.open({
      key: 'updatable',
      type: 'success',
      content: 'Excel File Ready.',
      duration: 5,
    }))

    setTableLoading(false)
  }

  return (
    <section className="main">
      <Content style={{ padding: "0 50px" }}>
        <div style={{ padding: "20px", borderRadius: "20px" }}>

          <Breadcrumb separator={<RightOutlined />} style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
            <Breadcrumb.Item>Bordereau</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Typography>
              <Title>Bordereau Report</Title>
            </Typography>

            <ConfigProvider theme={{ token: { colorPrimary: '#1d6f42', "borderRadius": 10, "fontSize": 14.5 } }}>
              {hasDownloadPermission && (
                <Button
                  disabled={isTableLoading}
                  onClick={() => handleExelBordereauReport()}
                  type='primary'
                  size='large'
                >
                  Download Excel <FileExcelOutlined />
                </Button>
              )}
            </ConfigProvider>
          </div>

          <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, height: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ backgroundColor: '#f5f5f5', paddingLeft: 20, paddingTop: 20, paddingRight: 20, marginRight: 10, borderRadius: 10, height: 'fit-content' }}>

                <div style={{ marginBottom: 20 }}>
                  <Title level={4}><SlidersOutlined /> Filters</Title>

                </div>
                {/* <Title level={5}>Filters</Title> */}

                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onSearchSubmit}
                  style={{ maxWidth: 400, minWidth: 400 }}
                  initialValues={searchFilters}
                  autoComplete="off"
                >

                  <Form.Item
                    name="saleDate"
                    label="Sale Date"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value || getFieldValue("saleDate") || getFieldValue("saleDate")) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("At least one date field is required."));
                        },
                      }),
                    ]}
                  >
                    <RangePicker disabled={isSelectLoading} />
                  </Form.Item>

                  <Divider />

                  <Form.Item
                    name="verifiedCheckboxes"
                    label=""
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          value: 'sltaxfilled',
                          label: 'SL Tax Filled',
                        },
                        {
                          value: 'commissionreconciled',
                          label: 'Commission Reconciled',
                        },
                        {
                          value: 'nbaudit',
                          label: 'NB Audit',
                        },
                        {
                          value: 'renewalgenerated',
                          label: 'Renewal Generated',
                        },
                      ]}
                      disabled={isSelectLoading}
                    // style={{ display: 'flex', flexDirection: 'column' }}
                    />
                  </Form.Item>

                  <Divider />

                  <Form.Item
                    name="umr"
                    label="UMR"
                    mode="multiple"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      placeholder="Select an UMR"
                      optionFilterProp="children"
                      mode="multiple"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctUMR.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="originatedFrom"
                    label="Originated From"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Select an originated from"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctOriginatedFrom.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="saleStage"
                    label="Sale Stage"
                    hasFeedback
                    validateStatus={!isSelectLoading ? "" : "validating"}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Select an originated from"
                      optionFilterProp="children"
                      disabled={isSelectLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={!isSelectLoading ? selectData.mDistinctSaleStage.map(a => ({
                        value: a.selectValues,
                        label: a.selectValues
                      })) : []}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" size='large' style={{ width: '100%' }} disabled={isSelectLoading || isTableLoading} htmlType="submit">
                      <SearchOutlined /> Search
                    </Button>
                  </Form.Item>


                </Form>
              </div>

              <div style={{ overflowX: "auto", marginLeft: 10 }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#194FA2',
                      colorPrimaryBg: 'ffffff',
                      // colorBorder: '#00B0B0',
                      "borderRadius": 10,
                      "fontSize": 14.5,
                      // "fontFamily": 'Poppins',
                      // 'colorPrimaryHover': '#00B0B0'
                    }
                  }}
                >
                  <Table
                    rowClassName={(record, index) => (record.verified == 'true' && 'green')}
                    loading={isTableLoading}
                    ref={mainTable}
                    rowKey={(record) => record.Ref}
                    columns={columns.map(column => {
                      return { width: 200, ...column };
                    })}
                    // rowSelection={{ ...rowSelection }}
                    dataSource={tableData}
                    // size="small"
                    onChange={(e) => handlePageChange(e)}
                    footer={(currentTable) => `Showing ${currentOffset} - ${currentOffset + currentTable.length} of ${registryCount} total`}
                    // rowClassName={(e) => (console.log(e))}
                    pagination={{
                      current: currentPage,
                      position: ["bottomCenter"],
                      defaultCurrent: 1,
                      pageSize: 100,
                      total: registryCount,
                      showSizeChanger: false,
                    }}
                    scroll={{ y: 700 }}
                  // scroll={{ y: windowSize.height / 1.5 }}
                  />
                </ConfigProvider>
              </div>

            </div>

            <div
              style={{
                position: "fixed",
                bottom: 5,
                right: 5,
                margin: "1.5em"
              }}
            >
              {selectedRowCount != 0 && (
                <div>
                  {/* <Button
                    type="dashed"
                    size={"large"}
                    style={
                      { boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', margin: 10, width: 200 }}
                    onClick={() => handleClearSelection()}
                    loading={isSelectLoading}
                  >
                    {'Clear Selection '}
                  </Button> */}
                  <Button
                    type="primary"
                    size={"large"}
                    style={
                      // !hasSelected
                      //   ? { transform: "scale(0)" }
                      //   : { marginRight: 12, transform: "scale(1)" }
                      { boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', margin: 0, width: 150 }
                    }
                    onClick={() => handleApprove()}
                    loading={isSelectLoading}
                  >
                    {'Verify ' + selectedRowCount + (selectedRowCount == 1 ? ' row' : ' rows')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        {contextHolder}
      </Content>
    </section>
  );
}

export default MainSection;
