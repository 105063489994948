import { getUserData } from "../../Layouts/Navbar/Requests";
import {
  Typography,
  Layout,
  Breadcrumb,
  Table,
  Form,
  DatePicker,
  Button,
  Select,
  Divider,
  Checkbox, Radio, ConfigProvider, Popover, message,
  Tooltip,Space ,Input, Modal 
} from "antd";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  SearchOutlined,
  RightOutlined,
  HomeOutlined,
  FileExcelOutlined,
  SlidersOutlined,FileAddOutlined
} from "@ant-design/icons";
import dayjs from 'dayjs';
import customStyle from './style.css'

import { getSLANumberData, getSLANumberRegistryCount,postSLANumber,postExcelDownload } from "../../redux/slice";
import _ from 'lodash';

const { Title } = Typography;
const { Content, Footer } = Layout;
const { RangePicker } = DatePicker;


function MainSection() {

  // Data loading
  const [tableData, setTableData] = useState([]);
  const [registryCount, setRegistryCount] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSelectLoading, setSelectLoading] = useState(true);
  const [isTableLoading, setTableLoading] = useState(true);
  const [searchFilters, setSearchFilters] = useState({ });

  const [searchValue, setSearchValue] = useState("");

  const [selectedRowCount, setSelectedRowCount] = useState(0)
  const [userData, setUserData] = useState([]);
  const [hasDownloadPermission, setDownloadPermission] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [formEdit] = Form.useForm();

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

    setSearchFilters({
      ...searchFilters,
      search: e.target.value,
    });
  };


  const addRecord = async (values) => {
    try {
      // Call the postSLANumber function with the form data
      const response = await postSLANumber(values);
  
      if (response.status === 200) {
        // Update the data table or other parts of your interface if needed
        getTableData(currentOffset);
        document.getElementById("formAdd").reset();
      } else {
        // Error handling in case the request is not successful.
        console.error('Error adding the record:', response.status, response.data);
      }
    } catch (error) {
      // Error handling in case an exception occurs during the request.
      console.error('Error adding the record:', error);
    }
  };
  
  const handleEditClick = (record) => {
    setIsEditModalVisible(true);
    formEdit.setFieldsValue(record);
    setEditFormData({ ...record }); 
  };

  const handleEditSave = async (values) => {
    try {
      console.log(values)
      const response = await postSLANumber(formEditRef.current.getFieldsValue());
  
      if (response.status === 200) {
        setIsEditModalVisible(false);
        getTableData(currentOffset);
      } else {
        console.error('Error editing the record:', response.status, response.data);
      }
    } catch (error) {
      console.error('Error editing the record:', error);
    }
  };

  const handleEditDelete = async (values) => {
    try {
      const formData = formEditRef.current.getFieldsValue();
      const updatedFormData = { ...formData, delete: 1 }; 
      const response = await postSLANumber(updatedFormData);
  
      if (response.status === 200) {
        setIsEditModalVisible(false);
        getTableData(currentOffset);
      } else {
        console.error('Error editing the record:', response.status, response.data);
      }
    } catch (error) {
      console.error('Error editing the record:', error);
    }
  };
  

  /*const removeRecord = async (key) => {
    try {
      console.log(values)
      const response = await postSLANumber(formEditRef.current.getFieldsValue());
  
      if (response.status === 200) {
        setIsEditModalVisible(false);
        getTableData(currentOffset);
      } else {
        console.error('Error editing the record:', response.status, response.data);
      }
    } catch (error) {
      console.error('Error editing the record:', error);
    }
  };*/
  // WINDOW SIZE LISTENER

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  async function getTableData(pOffset) {

    setTableLoading(true);

    let mSearchFilters = searchFilters
    mSearchFilters.offset = pOffset
    setCurrentOffset(pOffset)
    const ReportData = await getSLANumberData(mSearchFilters);

    setTableData(ReportData?.data?.searchResult);
    console.log(ReportData?.data?.searchResult)

    setTableLoading(false);

  }

  async function getPageCount() {
    const ReportRegistryCount = await getSLANumberRegistryCount(searchFilters);
    setRegistryCount(ReportRegistryCount?.data?.count);
  }

  // Poblate data on web load
  useEffect(async () => {
    Promise.all([ getPageCount(), getTableData(0)])
  }, []);

  // Seach filter useEffect
  const isFirstRender = useRef(true);

  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setTableLoading(true);
      getPageCount();
      getTableData(0);
    }

  }, [searchFilters]);

  // Modal Handling
  const mainTable = useRef(null);

  const handlePageChange = (e) => {
    setTableLoading(true)
    setCurrentPage(e.current)
    getTableData((e.current - 1) * 100)
  }

 const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 80, 
    },
    {
      title: 'Policy Number',
      dataIndex: 'policynumber',
      key: 'policynumber',
    },
    {
      title: 'NJSLA Number',
      dataIndex: 'njslanumber',
      key: 'njslanumber',
    },
    {
      title: 'SLA Number',
      dataIndex: 'sla',
      key: 'sla',
      width: 120, 
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 80, 
    },
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      width: 80, 
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditClick(record)}>Edit</Button>
        </Space>
        
      ),
    }, /*<Button onClick={() => removeRecord(record.key)}>Delete</Button>*/
  ];
  const formEditRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();

        setUserData(data);

        const hasDownloadPermission = data.navBarItems.some(item => {
          return item.children && item.children.some(child => child.name === 'report.download');
        });

        setDownloadPermission(hasDownloadPermission)

      } catch (error) {
        console.log("Error:", error.message);
      }
    };
    fetchData();
  }, []);

  // GET ACTIVITY REPORT AS EXCEL FILE
    const [messageApi, contextHolder] = message.useMessage();

    const handleExelReport = async () => {
      setTableLoading(true)
      messageApi.open({
        key: 'updatable',
        type: 'loading',
        content: 'Generating Excel File...',
        duration: 0,
      })
  
      await postExcelDownload(searchFilters, 'slanumber')
  
      !isTableLoading && (messageApi.open({
        key: 'updatable',
        type: 'success',
        content: 'Excel File Ready.',
        duration: 5,
      }))
  
      setTableLoading(false)
    }

  return (
    <section className="main">
      <Content style={{ padding: "0 50px" }}>
        <div style={{ padding: "20px", borderRadius: "20px" }}>

          <Breadcrumb separator={<RightOutlined />} style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Actions</Breadcrumb.Item>
            <Breadcrumb.Item>SLA Number</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Typography>
              <Title>SLA Number</Title>
            </Typography>

            <ConfigProvider theme={{ token: { colorPrimary: '#1d6f42', "borderRadius": 10, "fontSize": 14.5 } }}>
              {hasDownloadPermission && (
                <Button
                  disabled={isTableLoading}
                  onClick={() => handleExelReport()}
                  type='primary'
                  size='large'
                >
                  Download Excel <FileExcelOutlined />
                </Button>
              )}
            </ConfigProvider>
          </div>

          <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, height: '100%' }}>
            <div style={{ display: 'flex' }}>

            <div style={{ backgroundColor: '#f5f5f5', paddingLeft: 20, paddingTop: 20, paddingRight: 20, marginRight: 10, borderRadius: 10, height: 'fit-content' }}>

              <Input
                    placeholder="Search by policy/SLA ..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    style={{ marginBottom: 16 }}
              />
              <div style={{ marginBottom: 20 }}>
                <Title level={4}><FileAddOutlined /> New SLA Number</Title>
              </div>
              {/* <Title level={5}>Filters</Title> */}
            
              <div>
                <Form id="formAdd"
                onFinish={addRecord} 
                style={{ maxWidth: 300, minWidth: 255 }}>
                  <Form.Item name="policynumber" label="Policy Number"  rules={[
                    {
                      required: true,
                      message: "Please enter Policy Number!",
                    },
                  ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="njslanumber" label="NJSLA Number"  rules={[
                    {
                      required: true,
                      message: "Please enter NJSLA Number!",
                    },
                  ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="sla" label="SLA Number"  rules={[
                    {
                      required: true,
                      message: "Please enter SLA Number!",
                    },
                  ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="year" label="Year"  rules={[
                    {
                      required: true,
                      message: "Please enter Year!",
                    },
                  ]}>
                    <Input type="number" />
                  </Form.Item>
                  
                  <Form.Item name="row" label="Row #"  rules={[
                    {
                      required: true,
                      message: "Please enter Row!",
                    },
                  ]}>
                    <Input type="number" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add New
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>

              <div style={{ overflowX: "auto", marginLeft: 10 }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#194FA2',
                      colorPrimaryBg: 'ffffff',
                      // colorBorder: '#00B0B0',
                      "borderRadius": 10,
                      "fontSize": 14.5,
                      // "fontFamily": 'Poppins',
                      // 'colorPrimaryHover': '#00B0B0'
                    }
                  }}
                >
                  <Table
                    rowClassName={(record, index) => (record.verified == 'true' && 'green')}
                    loading={isTableLoading}
                    ref={mainTable}
                    rowKey={(record) => record.Ref}
                    columns={columns.map(column => {
                      return { width: 200, ...column };
                    })}
                    // rowSelection={{ ...rowSelection }}
                    dataSource={tableData}
                    // size="small"
                    onChange={(e) => handlePageChange(e)}
                    footer={(currentTable) => `Showing ${currentOffset} - ${currentOffset + currentTable.length} of ${registryCount} total`}
                    // rowClassName={(e) => (console.log(e))}
                    pagination={{
                      current: currentPage,
                      position: ["bottomCenter"],
                      defaultCurrent: 1,
                      pageSize: 100,
                      total: registryCount,
                      showSizeChanger: false,
                    }}
                    scroll={{ y: 700 }}
                  // scroll={{ y: windowSize.height / 1.5 }}
                  />
                </ConfigProvider>
              </div>

            </div>

            <div
              style={{
                position: "fixed",
                bottom: 5,
                right: 5,
                margin: "1.5em"
              }}
            >
            
            </div>
          </div>
        </div>
        <Modal
          title="Edit Data"
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          footer={[
            <Button key="cancel" onClick={() => setIsEditModalVisible(false)}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleEditSave}>
              Save
            </Button>,
            <Button key="delete" danger="true" onClick={handleEditDelete}>
            Delete
          </Button>,
          ]}
        >
          <Form  id="formEdit"
            form={formEdit}
            initialValues={editFormData}
            onFinish={handleEditSave}
            ref={formEditRef}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              label="Id"
              name="id"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Policy Number"
              name="policynumber"
              rules={[
                {
                  required: true,
                  message: "Please enter Policy Number!",
                },
              ]}
            >

              <Input />
            </Form.Item>

            <Form.Item
              label="NJSLA Number"
              name="njslanumber"
              rules={[
                {
                  required: true,
                  message: "Please enter NJSLA Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>


            <Form.Item
              label="SLA Number"
              name="sla"
              rules={[
                {
                  required: true,
                  message: "Please enter SLA Number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Year"
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please enter Year!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Row #"
              name="row"
              rules={[
                {
                  required: true,
                  message: "Please enter Row!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        {contextHolder}
      </Content>
    </section>
  );
}

export default MainSection;
