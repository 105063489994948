import ReactDOM from "react-dom";
// import 'antd/dist/antd.css';
import "./Resources/index.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Divider,
  Typography,
  Modal,
  notification,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
// import { useHistory } from 'react-router-dom'
// import Cookies from 'js-cookie';

import { signIn } from "./Scripts/Requests";
import { useLocation } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState("validating");
  const [modal, modalContextHolder] = Modal.useModal();
  const [notif, notificationContextHolder] = notification.useNotification();

  // REDIRECTION RELATIVE PATH
  const forwardUrl = localStorage.getItem("forwardUrl");
  const location = useLocation();

  let relativePath = "";

  const openNotificationWithIcon = () => {
    notif["error"]({
      message: "Login Failed",
      description: "The username or password are invalid!",
    });
  };

  const unknownErrorConfig = {
    title: "Error",
    centered: true,
    content: <>An unknown error has happened! Please contact the admins.</>,
  };

  const onFinish = async (postParameters) => {
    setLoading(true);
    var loginResponse = await signIn(postParameters);
    console.log(loginResponse, postParameters);

    if (loginResponse.status == 200) {
      const myUrl = localStorage.getItem("forwardUrl");
      window.location.href = myUrl !== null ? myUrl : "/home";
      localStorage.setItem("forwardUrl", "/");
    } else if (loginResponse.status == 404) {
      setErrorStatus("error");
      openNotificationWithIcon();
    } else {
      modal.error(unknownErrorConfig);
    }

    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        height: 600,
        borderRadius: 15,
        alignItems: "center",
        boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08)",
        display: "flex",
        width: 1100,
        left: "50%",
        justifyContent: "space-evenly",
        top: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        padding: 30,
      }}
    >
      <div class="login100-pic js-tilt" data-tilt>
        <img
          style={{ height: "100px", marginBottom: 60 }}
          src="https://flowflood.westus3.cloudapp.azure.com/api/resources/common/img/logo-dark.svg"
          alt="IMG"
        />
      </div>

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Typography>
          <Title style={{ marginBottom: 30, fontFamily: "Poppins" }} level={4}>
            User Login
          </Title>
        </Typography>

        <Form.Item
          name="username"
          validateStatus={errorStatus}
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input
            size={"large"}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          name="pass"
          validateStatus={errorStatus}
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            prefix={<KeyOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            size={"large"}
          />
        </Form.Item>

        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading}
            size={"large"}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>

      {modalContextHolder}
      {notificationContextHolder}
    </div>
  );
};

export default Login;
