import React, { useEffect, useState, useRef } from "react";
import { Navbar } from "../../Layouts/Navbar";
import { Typography } from "antd";
import { useLocation } from "react-router-dom";
// import jwt_decode from "jwt-decode";

const { Title } = Typography;

const env = "production";

function MainSection() {
  const [userData, setUserData] = useState(null);

  // let { pathname: location } = useLocation();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await getUserData();
  //     setUserData(data);
  //   };
  //   fetchData();
  // }, [location]);

  return (
    <Typography
    style={{paddingTop:'20vh'}}>
      {/* <Title
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Welcome!
      </Title> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src="https://flowflood.westus3.cloudapp.azure.com/api/resources/common/img/logo-dark.svg"
          alt="Flowflood"
          width="60%"
        />
      </div>
    </Typography>
  );
}

function StandardPopup() {
  return (
    <div className="standard_popup" id="standard_popup">
      <div className="standard_popup-header">
        <div
          className="title"
          style={{ width: "50%" }}
          id="title_standard_popup"
        ></div>
        {/* <button onClick={() => closestandard_popup()} className="close-button">&times;</button> */}
      </div>
      <div className="standard_popup-body" id="standard_popup_body"></div>
    </div>
  );
}

function Overlay() {
  return <div id="overlay"></div>;
}

function Scripts() {
  return (
    <>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/helper.js`}
      ></script>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/main.js`}
      ></script>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/pop-up-lib.js`}
      ></script>
      <script
        src={`https://mltply.eastus.cloudapp.azure.com/webservices/${env}/resources/flowSM/js/pop-ups.js`}
      ></script>
    </>
  );
}

export function LandingPage() {
  return (
    <>
      <Navbar />
      <MainSection />
    </>
  );
}

// export default Cancellations;
